import Keycloak from 'keycloak-js';

const auth = Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
});


export default auth;
