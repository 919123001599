import { createContext } from 'react';
import { initState } from './state';

import type { NavState,/*  NavAction */ } from './state';
// import type { Dispatch } from 'react';

export interface NavContext {
  state: NavState;
  dispatch?: any;
}

export default createContext<NavContext>({
  state: initState,
  dispatch: null
});
