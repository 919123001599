// import type { LabeledValue } from 'antd/es/select';
import IMapView from "esri/views/MapView";
import moment from "moment";
import 'moment/locale/it';

export const isString = (obj:any) =>{
  return (Object.prototype.toString.call(obj) === '[object String]');
}

export const isURL: (test: string) => boolean = (test: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return !!pattern.test(test);
};

export const getURLPathname = (url: string): string => {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url;
  }
};

export const stringNumbersToNumberArray = ({
  value,
  separator = ',',
}: {
  value: string;
  separator?: string;
}): number[] => value?.split(separator).map(Number);

/* export const createSelectOptions = <T = unknown>(
  data: T[],
  {
    value,
    label,
    key,
  }: {
    value: string | ((d: T) => string);
    label: string | ((d: T) => string);
    key?: string | ((d: T) => string);
  }
): LabeledValue[] => {
  return data?.map((c) => ({
    key: typeof key === 'function' ? key(c) : c[key],
    label: typeof label === 'function' ? label(c) : c[label],
    value: typeof value === 'function' ? value(c) : c[value],
  }));
}; */

export const makePercent =
  (accurancy: number) =>
  (dividend: number, divisor: number): number =>
    divisor === 0 ? divisor : +(dividend / divisor).toFixed(accurancy);

export const fourDigitPrecision = makePercent(4);


export const layerVisibleOnSearch = (mapView:IMapView, visible:boolean, disableLyrs:any[]) => { 
  mapView?.map.allLayers?.forEach((lyr: any) => {
    if(disableLyrs.includes(lyr.id)){
      lyr.visible = lyr.visible === visible ? lyr.visible : false;

    } else {

      if(lyr.sublayers){
        lyr.visible = true;
        if(lyr.sublayers && visible===false && !lyr.attributionDataUrl) {
          lyr.sublayers.forEach((sublayer: any) => {
            sublayer.visible = visible;
          });
        }
      } else if(lyr.url) {
          lyr.visible = visible;
      }
    }
  });  
};

export const authLayerRole = (enabledTo: any[], userInfo: any) => {
  let authOK:number = 0;
  enabledTo.forEach((role: any) => {
    userInfo.realm_access.roles.includes(role) && authOK++;
  });
  return authOK;
};

export const isMobileScreen = (view:IMapView) => {
  return view && ["xsmall", "small", "medium"].includes(view.widthBreakpoint);
}

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const getfieldInfosPopUp = (fields: any[]) => {
  const fieldInfo: any = [];
  fields.forEach((f) => {
    
    fieldInfo.push({
      fieldName: f.name,
      label: f.alias,
      format:  f.type==="date" ? {dateFormat: "short-date"} : {}
    });
  });
  return fieldInfo;
};

export const typeFieldFormat = ((field: any, type: string, dateFormat:string) => {
  if(field){
    switch (type) {
      case "date":
        field = moment(field).utc().format(dateFormat);
        break;
      default:
        break;
    }
  }
  return field;
});