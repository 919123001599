import * as React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BrowserRouter as Router } from "react-router-dom";
import { useQueryErrorResetBoundary } from "react-query";
import { ConfigProvider as AppSettingsProvider } from "@sigeo-registry/config-react";
import { ErrorBoundary } from "react-error-boundary";

import config from "./config";
import { auth } from "./services";
import { CirclelLoader } from "./components/loader";
import { getURLPathname } from "./helpers";
// import { Network } from './components/network';
import Error from "./components/errors/Error";
import NavProvider from "./helpers/Provider";

const App = React.lazy(() => import("./Main"));

type RootComponent = React.FC<{}>;

const Root: RootComponent = () => {
  const basename: string = getURLPathname(process.env.PUBLIC_URL);
  const { reset } = useQueryErrorResetBoundary();
  const stl: React.CSSProperties = { marginTop: "50vh" };
  return (
    <AppSettingsProvider config={config}>
      <ReactKeycloakProvider
        authClient={auth}
        LoadingComponent={
          <CirclelLoader style={stl}>init app...</CirclelLoader>
        }
        onTokens={(event) => {
          localStorage.setItem("authorization", event.token as string);
          window.dispatchEvent(new Event("storage"));
        }}
      >
        {/* <QueryClientProvider client={queryClient}> */}
        <Router basename={basename}>
          <ErrorBoundary onReset={reset} FallbackComponent={Error}>
            <React.Suspense
              fallback={<CirclelLoader style={stl}>init app...</CirclelLoader>}
            >
              {/* <Network> */}
                <NavProvider>
                  <App />
                </NavProvider>
              {/* </Network> */}
            </React.Suspense>
          </ErrorBoundary>
        </Router>
        {/*  </QueryClientProvider> */}
      </ReactKeycloakProvider>
    </AppSettingsProvider>
  );
};

export default Root;
