import { Config } from '@sigeo-registry/config-react';
import type { Settings } from '@sigeo-registry/config-react';
import type { KeycloakInitOptions } from 'keycloak-js';

const config = new Config({
  app: {
    locale: "it",
    theme: 'light',
    sidebar: {
      width: 210,
      collapsed: true,
    },
  },
  notification: {
    duration: 3,
    //seconds
  },
  websocket: {
    path: 'wss://fwc-dev.bizio.dev/websocket/message',
    keepAlive: {
      active: true,
      duration: 50000,
      //milliseconds
    },
  },
  query: {
    // queryCache: QueryCache;
    // mutationCache: MutationCache;
  },
  globalLoader: { showSpinner: false, speed: 500, minimum: 0.5 },

  jobs: {
    DATE_UI_FORMAT: 'DD-MM-YYYY',
    MAX_DAY_SEARCH: 180,
  },
  auth: {
    default: {
      onLoad: 'check-sso',
      enableLogging: false,
    } as KeycloakInitOptions,

    development: {
      enableLogging: false,
    } as KeycloakInitOptions,

    production: {
      enableLogging: false,
    } as KeycloakInitOptions,
  },
  version: "V 1.5",
  widget: {
    search: {
      disableLyrs: ["coperture26-tlLyr", "coperture35Wind-tlLyr", "coperture35Opnet-tlLyr", "settori26-ft", "settori35-ft", "clienti-ft"],
    },
    coverage: {
      disableLyrs: ["coperture26-tlLyr", "coperture35Wind-tlLyr", "coperture35Opnet-tlLyr",],
    },
  },
  map: {
    mapDefault: {
      center: [12, 42],
      zoom: 6,
      basemap: "osm",
      rotationEnabled: false,
      wkid: 3857,
    },
    interceptors: {
      url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb"
    },
    tokenService: {
      url: "https://fwa-tools.it/MapET-SFS/rest/tokens/",
      client: "keycloak"
    },
    lyrs: {
      pin:{
        id: "pin-gl",
        listMode: "hide",
        visible: true
      },
      protractor:{
        id: "protractor-gl",
        listMode: "hide",
        visible: false
      },
      buffer:{
        id: "buffer-gl",
        listMode: "hide",
        visible: true
      },
      cop26antenna:{
        id: "cop26antenna-gl",
        title: "Copertura Antenna 26 GHz",
        type: "graphics",
        listMode: "hide",
        visible: false,
        symbol: {
          type: "simple-fill",
          color: [ 0, 255, 0, 0.3 ],
          outline: {
            color: [0,0,0,0.1],
            width: 1
          },
        }
      },
      cop35antenna:{
        id: "cop35antenna-gl",
        title: "Copertura Antenna 3.5 GHz",
        type: "graphics",
        listMode: "hide",
        visible: false,
        symbol: {
          type: "simple-fill",
          color: [192,192,192,0.35],
          outline: {
            color: [0,0,0,0.1],
            width: 2
          },
        },
        color0: [192,192,192,0.35],
        color1: [26,83,255,0.35],
        color2: [204,0,102,0.35],
      },
      allarmi:{
        id: "allarmi",
        title: "Allarmi",
        type: "feature",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/allarmi/MapServer/0",
        dateFormat: "D/M/YYYY HH:mm",
        fields: [
          {
            name: "site_id",
            alias: "Codice Sito",
            type: "string",
          },
          {
            name: "ko_ts",
            alias: "Data KO",
            type: "date",
          },
          {
            name: "comune",
            alias: "Comune",
            type: "string",
          }
        ],
      },
      antenne26: {
        id: "antenne26-ft",
        type: "feature",
        titleInfoPopUp: `{codice_sito} [26 GHz]`,
        title: "Antenne 26 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/antenne/MapServer/0",
        listMode : "show",
        dateFormat: "D/M/YYYY",
        visible: false,
        opacity: 1,
        searchField: "codice_sito",
        labelingInfo: {
          fontSize: 10,
          minScale: 2000000,
          maxScale: 0,
          expression: "$feature.codice_sito"
        },
        actionsInfoPopUp: [
          {
            title: "Copertura 26 GHz",
            id: "cop26-antenna",
            className: "esri-icon-hollow-eye",
            type: "button"
          }],
        fields: [
          {
            name: "codice_sito",
            alias: "Codice Sito",
            type: "string",
          },
          {
            name: "attivo",
            alias: "Attivo",
            type: "string",
          },
          {
            name: "comune",
            alias: "Comune",
            type: "string",
          },
          {
            name: "tipo_sito",
            alias: "Tipo Sito",
            type: "string",
          },
          {
            name: "stato_sito",
            alias: "Stato Sito",
            type: "string",
          },        
          {
            name: "bts",
            alias: "Bts",
            type: "string",
          },
          {
            name: "vendor",
            alias: "Vendor",
            type: "string",
          },
          {
            name: "note",
            alias: "Note",
            type: "string",
          },
          {
            name: "data_apertura_26ghz",
            alias: "Data Apertura Sito",
            type: "date",
          },
          {
            name: "lat",
            alias: "Latitudine",
            type: "double",
          },
          {
            name: "lon",
            alias: "Longitudine",
            type: "double",
          },
        ],
      },
      antenne35: {
        id: "antenne35-ft",
        type: "feature",
        titleInfoPopUp: `{codice_sito} [3.5 GHz]`,
        title: "Antenne 3.5 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/antenne/MapServer/1",
        listMode : "show",
        dateFormat: "D/M/YYYY",
        visible: false,
        opacity: 1,
        searchField: "codice_sito",
        labelingInfo: {
          fontSize: 10,
          minScale: 2000000,
          maxScale: 0,
          expression: "$feature.codice_sito"
        },
        actionsInfoPopUp: [
        {
          title: "Copertura 3.5 GHz",
          id: "cop35-antenna",
          className: "esri-icon-hollow-eye",
          type: "button"
        }],
        fields: [
          {
            name: "codice_sito",
            alias: "Codice Sito",
            type: "string",
          },
          {
            name: "attivo",
            alias: "Attivo",
            type: "string",
          },
          {
            name: "codice_sito_mappa",
            alias: "Codice Sito mappa",
            type: "string",
          },
          {
            name: "stato_sito",
            alias: "Stato Sito",
            type: "string",
          },
          {
            name: "tipo_sito",
            alias: "Tipo Sito",
            type: "string",
          },
          {
            name: "comune",
            alias: "Comune",
            type: "string",
          },      
          {
            name: "bts",
            alias: "Bts",
            type: "string",
          },
          {
            name: "vendor",
            alias: "Vendor",
            type: "string",
          },
          {
            name: "Note",
            alias: "Note",
            type: "string",
          },
          {
            name: "data_apertura_3_5ghz",
            alias: "Data Apertura Sito",
            type: "date",
          },
          {
            name: "lat",
            alias: "Latitudine",
            type: "double",
          },
          {
            name: "lon",
            alias: "Longitudine",
            type: "double",
          },
        ]
      },
      settori: {
        id: "settori-imgLyr",
        type: "mapImage",
        title: "Settori",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/settori/MapServer",
        dateFormat: "D/M/YYYY",
        visible: true,
        enabledTo: ['vendite', 'supporto', 'system'],
        opacity: 1,
        sublayers: [{
          id: 1,
          title: "Settori 3.5 GHz",
          visible: false,
          opacity: 0.7,
          popupTemplate: {
            title: "Settori 3.5 GHz",
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "azimut",
                    label: "Azimut",
                  },
                  {
                    fieldName: "pci",
                    label: "PCI",
                  },  
                ],
              },
            ],
          }
        },{
          id: 0,
          title: "Settori 26 GHz",
          visible: false,
          opacity: 0.7,
          popupTemplate: {
            title: "Settori 26 GHz",
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "azimut",
                    label: "Azimut",
                  },
                  {
                    fieldName: "pci",
                    label: "PCI",
                  }, 
                ],
              },
            ],
          }
        }]
      },
      coperture: {
        id: "coperture-imgLyr",
        type: "mapImage",
        title: "Coperture",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/coperture/MapServer",
        dateFormat: "D/M/YYYY",
        visible: true,
        enabledTo: ['vendite', 'supporto', 'system'],
        opacity: 1,
        sublayers: [ {
          id: 1,
          title: "Coperture 3.5 GHz",
          visible: false,
          opacity: 0.5,
        },{
          id: 0,
          title: "Coperture 26 GHz",
          visible: false,
          opacity: 0.5,
        }]
      },
      coperture26: {
        id: "coperture26-tlLyr",
        type: "tile",
        title: "Coperture 26 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/coperture_26/MapServer",
        dateFormat: "D/M/YYYY",
        enabledTo: ['vendite', 'supporto', 'system'],
        visible: false,
        opacity: 0.5,
      },
      /*coperture35: {
        id: "coperture35-tlLyr",
        type: "tile",
        title: "Coperture 3.5 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/coperture_35/MapServer",
        dateFormat: "D/M/YYYY",
        enabledTo: ['vendite', 'supporto', 'system'],
        visible: false,
        opacity: 0.5,
      },*/
      coperture35wind: {
        id: "coperture35Wind-tlLyr",
        type: "tile",
        title: "Coperture Wind 3.5 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/coperture_35_wind/MapServer",
        dateFormat: "D/M/YYYY",
        enabledTo: ['vendite', 'supporto', 'system'],
        visible: false,
        opacity: 0.5,
      },
      coperture35opnet: {
        id: "coperture35Opnet-tlLyr",
        type: "tile",
        title: "Coperture Opnet 3.5 GHz",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/coperture_35_opnet/MapServer",
        dateFormat: "D/M/YYYY",
        enabledTo: ['vendite', 'supporto', 'system'],
        visible: false,
        opacity: 0.5,
      },
      clienti: {
        id: "clienti-imgLyr",
        title: "Test",
        url: "https://fwa-tools.it/MapET-SFS/rest/services/Fastweb/clienti_attivi/MapServer",
        dateFormat: "D/M/YYYY",
        visible: true,
        enabledTo: ['supporto'],
        opacity: 1,
        searchField: "Classe_Cliente",
        sublayers: [{
          id: 0,
          title: "Test",
          visible: false,
          opacity: 1,
          popupTemplate: {
            title: "Test",
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "account_number",
                    label: "Account Number",
                  },
                  {
                    fieldName: "classe_cliente",
                    label: "Classe Cliente",
                  },
                  {
                    fieldName: "codice_di_chiusura",
                    label: "Codice di Chiusura",
                  },
                  {
                    fieldName: "site_room",
                    label: "Sito",
                  },
                  {
                    fieldName: "building",
                    label: "Struttura",
                  },
                  {
                    fieldName: "floor",
                    label: "Piano",
                  },
                  {
                    fieldName: "down_speed",
                    label: "Speed Download",
                  },                  
                  {
                    fieldName: "up_speed",
                    label: "Speed Upload",
                  },                  
                  {
                    fieldName: "sinr",
                    label: "SINR",
                  },
                  {
                    fieldName: "rsrp",
                    label: "RSRP",
                  },                  
                  {
                    fieldName: "frequenza_cpe",
                    label: "Frequenza CPE",
                  },                  
                  {
                    fieldName: "frequenza_di_copertura",
                    label: "Frequenza di Copertura",
                  },
                  {
                    fieldName: "latitudine",
                    label: "Laitudine",
                  },
                  {
                    fieldName: "longitudine",
                    label: "Longitudine",
                  },
                ],
              },
            ],
          }
        }],
        fields: [
          {
            name: "Classe_Cliente",
            alias: "Classe Cliente",
            type: "string",
          },
          {
            name: "Account_Number",
            alias: "Account Number",
            type: "string",
          },
          {
            name: "Codice_di_Chiusura",
            alias: "Codice di Chiusura",
            type: "string",
          },
          {
            name: "Building",
            alias: "Struttura",
            type: "string",
          },
          {
            name: "Floor",
            alias: "Piano",
            type: "string",
          },
          {
            name: "Site_Room",
            alias: "Sito",
            type: "string",
          },
        ],
      }
    },
    symbol: {
      pinYellow: {
        data: {
          type: "CIMSymbolReference",
          symbol: {
            type: "CIMPointSymbol",
            symbolLayers: [
              {
                type: "CIMVectorMarker",
                enable: true,
                anchorPoint: {
                  x: 0,
                  y: -12,
                },
                size: 25,
                frame: {
                  xmin: 0,
                  ymin: 0,
                  xmax: 66,
                  ymax: 133,
                },
                markerGraphics: [
                  {
                    type: "CIMMarkerGraphic",
                    textString: "",
                    primitiveName: "",
                    geometry: {
                      rings: [
                        [
                          [33, 132],
                          [25.4, 131.6],
                          [18.8, 130.3],
                          [13.1, 128.2],
                          [8.5, 125.3],
                          [4.9, 121.5],
                          [2.4, 117],
                          [0.8, 111.6],
                          [0.3, 105.4],
                          [0.3, 35.8],
                          [33, 1],
                          [65.8, 35.8],
                          [65.8, 105.4],
                          [65.3, 111.6],
                          [63.7, 117],
                          [61.2, 121.5],
                          [57.5, 125.3],
                          [52.9, 128.2],
                          [47.3, 130.3],
                          [40.6, 131.6],
                          [33, 132],
                        ],
                        [
                          [33, 73],
                          [29.9, 73.3],
                          [26.8, 74.1],
                          [24, 75.5],
                          [21.4, 77.3],
                          [19.2, 79.5],
                          [17.4, 82.1],
                          [16.1, 84.9],
                          [15.3, 88],
                          [15, 91.1],
                          [16.3, 98.1],
                          [20.2, 104.1],
                          [26, 108.1],
                          [33, 109.5],
                          [35.9, 109.3],
                          [38.6, 108.6],
                          [41.3, 107.5],
                          [43.7, 105.9],
                          [45.8, 104.1],
                          [47.7, 101.9],
                          [49.1, 99.4],
                          [50.2, 96.7],
                          [50.8, 94],
                          [51, 91.1],
                          [50.7, 88],
                          [49.9, 84.9],
                          [48.6, 82.1],
                          [46.8, 79.5],
                          [44.6, 77.3],
                          [42, 75.5],
                          [39.2, 74.1],
                          [36.1, 73.3],
                          [33, 73],
                        ],
                      ],
                    },
                    symbol: {
                      type: "CIMPolygonSymbol",
                      symbolLayers: [
                        {
                          type: "CIMSolidFill",
                          enable: true,
                          color: [255, 221, 0, 255],
                        },
                      ],
                    },
                  },
                ],
                scaleSymbolsProportionally: true,
                respectFrame: true,
              },
            
            ],
          },
        },
      }
    },
  },
} as Settings<{}>);

export default config;
