import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

export interface CircleLoaderProps {
  style?: React.CSSProperties;
  children: any;
}

export type CircleLoaderComponent = React.FC<CircleLoaderProps>;

const CircleLoader: CircleLoaderComponent = ({ style, children }) => {
  const stl: React.CSSProperties = {
    textTransform: "uppercase",
    textAlign: "center",
    width: "100vw",
    ...style,
  };

  return (
    <div style={stl} className="loader circle-loader">
      <Container className="loader circle-loader" fluid>
        <Row>
          <Col>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default CircleLoader;
