import * as React from 'react';
import NavContext from './Context';
import reducerHandler, { initState } from './state';

import type { NavReducer } from './state';
import type { NavContext as NavContextType } from './Context';

type NavProviderProps = React.PropsWithChildren<{}>;

const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer<NavReducer>(
    reducerHandler,
    initState
  );

  const ctx: NavContextType = React.useMemo<NavContextType>(
    () => ({ dispatch, state }),
    [state, dispatch]
  );

  return (
    <NavContext.Provider value={ctx}>{children}</NavContext.Provider>
  );
};

export default NavProvider;
