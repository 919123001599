import Point from 'esri/geometry/Point';
import type { Reducer } from 'react';
import { isString } from './utils';
export type ActionType = "open" | "close" | "clear";

export interface NavState {
  basemaps?: ActionType;
  legend?: ActionType;
  alarms?: ActionType;
  // info?: ActionType;
  elevationProfile?: ActionType;
  layerlist?: ActionType;
  settings?: ActionType;
  results?: any | Point;
  radiusBuffer?: any;
 }

export interface NavAction {
  id: string;
  state?: ActionType;
 }
export type NavReducer = Reducer<NavState, NavAction>;

export const initState: NavState = {
  basemaps: "close",
  alarms: "close",
  legend: "close",
  layerlist: "close",
  settings: "close",
  // info: "open",
  elevationProfile: "close",
};

const reducer: NavReducer = (state, action): NavState => {
  // console.log("state", action.state);
  let res:NavState = initState;
  if(!isString(action.state)){
    switch (action.id) {
      case "results":
        res = { ...state, elevationProfile: "close", "results": action.state};
        break;
      case "radiusBuffer":
        res = { ...state, elevationProfile: "close", "radiusBuffer": action.state};
        break;
    }
  } else {
    switch (action.state) {
      case "clear":
        res = {...state, "results": "clear"};
        break;
      case "open":
      case "close":
        res = { ...initState, [action.id]: action.state, "results": state?.results, "radiusBuffer": state?.radiusBuffer};
        break;
    }
  }
  return res;
};

export default reducer;
